import { template as template_321f7d2392164d24af80f128705286a6 } from "@ember/template-compiler";
const WelcomeHeader = template_321f7d2392164d24af80f128705286a6(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
