import { template as template_67131b8b6f4b412593461745aec39225 } from "@ember/template-compiler";
const FKText = template_67131b8b6f4b412593461745aec39225(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
