import { template as template_879b68cd84044347ac9462656b14b65c } from "@ember/template-compiler";
const FKControlMenuContainer = template_879b68cd84044347ac9462656b14b65c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
